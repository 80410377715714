<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-4"
          data-testid="add-employee-btn"
          @click="router.push({ name: 'employee-add' })"
        >
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.add") }}</span>
        </PrimeButton>

        <PrimeButton class="c-circular-button mr-4" @click="$emit('onExport')">
          <i class="pi pi-upload c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("employee.export") }}</span>
        </PrimeButton>

        <PrimeButton
          class="c-circular-button mr-4"
          @click="emit('employeesListsRefresh')"
          data-testid="open-picking-lists-refresh-button"
        >
          <i class="pi pi-refresh c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.reload") }}</span>
        </PrimeButton>
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <FilterOverview v-model:filters="filtersComputed" />
        <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-4">{{
            showSidebar ? t("common.filters.hide-filter") : t("common.filters.show-filter")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { EmployeeSearchFilters } from "@/repositories/employee/model/EmployeeSearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import FilterOverview from "./FilterOverview.vue";

const router = useRouter();

const { t } = useI18n();

const props = defineProps<{
  showSidebar: boolean;
  filters: EmployeeSearchFilters;
}>();
const emit = defineEmits<{
  (e: "onExport"): void;
  (e: "employeesListsRefresh"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: EmployeeSearchFilters): void;
}>();

const filtersComputed = computed({
  get() {
    return props.filters;
  },
  set(value: EmployeeSearchFilters) {
    emit("update:filters", value);
  },
});
</script>
<style scoped lang="scss">
:deep(.p-button-icon, .pi-upload) {
  background-color: var(--default-btn-bg);
  padding: 0.3rem;
  border-radius: 50%;
  color: var(--primary-color-text);
}
</style>
