import { setupI18n } from "@cumulus/locale";
import { type LocaleMessageDictionary, type VueMessageType } from "vue-i18n";
import defaultCommonLocale from "./en.json";
import defaultPrintingLocale from "../printing/locales/en.json";

async function loadMessagesAsync(locale: string): Promise<LocaleMessageDictionary<VueMessageType>> {
  const commonMessages = await import(`../locales/${locale}.json`);
  const printingMessages = await import(`../printing/locales/${locale}.json`);

  const messages = {
    ...commonMessages.default,
    ...printingMessages.default,
  };
  return messages as LocaleMessageDictionary<VueMessageType>;
}

export const i18n = setupI18n({ ...defaultCommonLocale, ...defaultPrintingLocale }, loadMessagesAsync);
