import { useAuth } from "@cumulus/event-bus";
import { companyApi } from "./CompanyApi";
import { type Company } from "./model/Company";
import { useErrorHandler } from "../ErrorHandler";

export function useCompany() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCompany = async (): Promise<Company> => {
    try {
      const authHeaders = await getAuthHeaders();
      const company = await companyApi.getCompany(authHeaders);
      return company;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCompany = async (company: Company): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await companyApi.update(authHeaders, company);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getCompany,
    updateCompany,
  };
}
