<template>
  <label for="business-number">{{ t("client.business-number") }}</label>
  <InputMask
    mask="999 999 999"
    v-model="businessNo"
    :class="{ 'p-invalid': val.businessNumber.$error }"
    class="inputfield w-full"
    :placeholder="t('placeholder.select', { property: t('client.business-number').toLowerCase() })"
    id="business-number"
    @blur="updateBusinessNumber"
  />
  <small data-testid="client-business-number-error" class="p-error" v-if="val.businessNumber.$error">{{
    val.businessNumber.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  businessNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:businessNumber", value: string | null): void;
}>();

const { t } = useI18n();

const rules = {
  businessNumber: {
    required,
  },
};

const val = useVuelidate(rules, props);

const businessNo = ref<string>(props.businessNumber ?? "");
watch(
  () => props.businessNumber,
  (value) => (businessNo.value = value ?? ""),
);
const updateBusinessNumber = () => {
  emit("update:businessNumber", businessNo.value.replace(/[\s_]+/g, ""));
};
</script>
