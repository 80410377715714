<template>
  <div id="company-navbar-shortcuts" v-if="useTeleport">
    <Teleport to="#c-navbar-title">
      <h1>{{ t("company.header-info") }}</h1>
    </Teleport>
    <Teleport to="#c-navbar-shortcuts">
      <div class="c-header-icon-container">
        <Button class="c-circular-button" @click="$emit('onClose')" data-testid="btn-close">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.close") }}</span></Button
        >
      </div>
    </Teleport>
  </div>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <h1>{{ t("company.header-info") }}</h1>
      <div class="c-header-icon-container ml-4">
        <Button class="c-circular-button" @click="$emit('onClose')" data-testid="btn-close">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.close") }}</span></Button
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");

defineEmits<{
  (e: "onClose"): void;
}>();
</script>
