import { httpClient, AuthHeaders } from "@cumulus/http";
import { Warehouse } from "../model/Warehouse";

class WarehouseApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_WAREHOUSE != undefined && import.meta.env.VITE_APP_API_WAREHOUSE
        ? import.meta.env.VITE_APP_API_WAREHOUSE + "/warehouses"
        : `${import.meta.env.VITE_APP_API_URL as string}/warehouses`;
  }

  public async getWarehouses(authHeaders: AuthHeaders): Promise<Warehouse[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then((response) => response.data);
  }
}

const warehouseApi = new WarehouseApi();
export { warehouseApi };
