import { UserPreferences } from "@cumulus/event-bus";
import { EmployeeState } from "./EmployeeState";

export class Employee {
  id = "";
  companyId = "";
  defaultClientId = "";
  warehouseId = "";
  firstName = "";
  lastName = "";
  initials = "";
  email = "";
  phoneNumber = "";
  startDate = new Date().toJSON();
  deactivationDate = "";
  clients: string[] = [];
  roles: string[] = [];
  employeeState: EmployeeState = EmployeeState.Active;
  languageCode = "NO";
  active = true;
  preferences: UserPreferences = new UserPreferences();
}
