import { type AuthHeaders } from "@cumulus/event-bus";
import { httpClient } from "@cumulus/http";
import { Printer } from "../models/Printer";
import { DefaultPrinterInfo } from "../models/DefaultPrinterInfo";
import { DocumentType } from "../models/DocumentType";

class DocumentApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_DOCUMENT != undefined && import.meta.env.VITE_APP_API_DOCUMENT
        ? import.meta.env.VITE_APP_API_DOCUMENT
        : `${import.meta.env.VITE_APP_API_URL}`;
  }

  public async getAllPrinters(authHeaders: AuthHeaders): Promise<Printer[]> {
    return httpClient(this.uri, authHeaders)
      .get("/printing/printers")
      .then(({ data }) => data);
  }

  public async getDefaultPrinters(authHeaders: AuthHeaders): Promise<DefaultPrinterInfo[]> {
    return httpClient(this.uri, authHeaders)
      .get("/printing/default-printers")
      .then(({ data }) => data);
  }

  public async setDefaultPrinter(authHeaders: AuthHeaders, printerInfo: DefaultPrinterInfo): Promise<void> {
    return httpClient(this.uri, authHeaders).put("/printing/default-printers", printerInfo);
  }

  public async deleteDefaultPrinter(authHeaders: AuthHeaders, documentType: DocumentType): Promise<void> {
    return httpClient(this.uri, authHeaders).delete(`/printing/default-printers/${documentType}`);
  }
}

export const documentApi = new DocumentApi();
