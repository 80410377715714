<template>
  <PrintingHeader @refreshClicked="getPrintingData" />
  <div class="c-printing-margin">
    <div class="grid grid-cols-12 gap-4">
      <div v-if="printixNotFound" class="col-span-12 mt-2 ml-2" data-testid="printix-not-found-message">
        <i18n-t keypath="printing.setup-printix-message" tag="span" for="printing.printix">
          <a href="https://manuals.printix.net/administrator/1/en/topic/getting-started" target="_blank">{{
            t("printing.printix")
          }}</a>
        </i18n-t>
      </div>
      <div class="col-span-12">
        <Card>
          <template #content>
            <h1>{{ t("printing.default-printers") }}</h1>
            <DefaultPrintersList
              :printers="defaultPrinters"
              :loading="loading"
              @defaultPrinterClicked="onDefaultPrinterClicked"
            />
          </template>
        </Card>
      </div>
      <div class="col-span-12">
        <Card>
          <template #content>
            <h1>{{ t("printing.available-printers") }}</h1>
            <AvailablePrintersList :printers="printers" :loading="loading" />
          </template>
        </Card>
      </div>
    </div>
  </div>

  <SetDefaultPrinterDialog
    v-if="setDefaultPrinterDialogVisible"
    v-model:visibleDialog="setDefaultPrinterDialogVisible"
    :printers="printers"
    :defaultPrinterInfo="defaultPrinterInfo"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useDocumentService } from "../api/DocumentService";
import { NotFoundError } from "@cumulus/http";
import { Printer } from "../models/Printer";
import AvailablePrintersList from "../components/AvailablePrintersList.vue";
import DefaultPrintersList from "../components/DefaultPrintersList.vue";
import { useI18n } from "vue-i18n";
import { DefaultPrinterInfo } from "../models/DefaultPrinterInfo";
import SetDefaultPrinterDialog from "../components/SetDefaultPrinterDialog.vue";
import PrintingHeader from "../components/PrintingHeader.vue";

const { t } = useI18n();
const { getAllPrinters, getAllDefaultPrinters } = useDocumentService();

const printers = ref<Printer[]>([]);
const defaultPrinters = ref<DefaultPrinterInfo[]>([]);
const defaultPrinterInfo = ref<DefaultPrinterInfo>(new DefaultPrinterInfo());
const loading = ref<boolean>(false);
const printixNotFound = ref<boolean>(false);
const setDefaultPrinterDialogVisible = ref<boolean>(false);

const getPrintingData = async () => {
  try {
    loading.value = true;
    printers.value = await getAllPrinters();
    defaultPrinters.value = await getAllDefaultPrinters();
  } catch (error) {
    if (error instanceof NotFoundError) {
      printixNotFound.value = true;
    }
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  getPrintingData();
});

const onDefaultPrinterClicked = (printer: DefaultPrinterInfo) => {
  defaultPrinterInfo.value = printer;
  setDefaultPrinterDialogVisible.value = true;
};
</script>

<style scoped lang="scss">
.c-printing-margin {
  margin: var(--default-content-margin);
}
</style>
