<template>
  <label for="phone-number"> {{ t("employee.phone-number") }} </label>
  <InputMask
    mask="999 99 999"
    v-model="phoneNo"
    id="phone-number"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.phoneNumber.$error }"
    :placeholder="t('placeholder.select', { property: t('employee.phone-number').toLowerCase() })"
    maxlength="8000"
    @blur="updatePhoneNumber"
  />
  <small class="p-error" v-if="val.phoneNumber.$error" data-testid="phone-number-error">{{
    val.phoneNumber.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required, minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  phoneNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:phoneNumber", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  phoneNumber: {
    required,
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(8)),
  },
};

const val = useValidate(rules, props);

const phoneNo = ref<string>(props.phoneNumber ?? "");
watch(
  () => props.phoneNumber,
  (value) => (phoneNo.value = value ?? ""),
);
const updatePhoneNumber = () => {
  emit("update:phoneNumber", phoneNo.value.replace(/[\s_]+/g, ""));
};
</script>
