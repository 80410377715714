import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Employee } from "./model/Employee";
import { NewEmployee } from "./model/NewEmployee";

class EmployeeApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_COMPANY != undefined && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY + "/employees"
        : `${import.meta.env.VITE_APP_API_URL as string}/employees`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Employee[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then((response) => response.data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Employee> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }

  public async create(authHeaders: AuthHeaders, employee: NewEmployee): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", employee);
  }

  public async update(authHeaders: AuthHeaders, employee: Employee): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", employee);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}

const employeeApi = new EmployeeApi();
export { employeeApi };
