<template>
  <DataTable
    :value="printers"
    stripedRows
    dataKey="id"
    class="c-datatable"
    responsiveLayout="scroll"
    scrollHeight="400px"
    :loading="loading"
    :rowHover="true"
    data-testid="available-printers-list"
    removableSort
    :rows="pageSize"
    :paginator="true"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :currentPageReportTemplate="
      t('common.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
  >
    <Column field="name" :header="t('printing.name')" :sortable="true"></Column>
    <Column field="supportedFormats" :header="t('printing.formats')" :sortable="true">
      <template #body="{ data }">
        {{ data.supportedFormats.join(", ") }}
      </template>
    </Column>
    <Column field="status" :header="t('printing.status')" :sortable="true">
      <template #body="{ data }">
        {{ t(`printing.connection-status.${data.status.toLowerCase()}`) }}
      </template>
    </Column>
    <template #empty>
      {{ t("printing.no-printers-found") }}
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Printer } from "../models/Printer";
import { ref } from "vue";

defineProps<{ printers: Printer[]; loading: boolean }>();

const { t } = useI18n();
const pageSize = ref<number>(25);
</script>

<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
