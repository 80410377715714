<template>
  <label for="email">{{ t("client.email") }}</label>
  <InputText
    id="email"
    type="text"
    v-model="emailComputed"
    data-testid="client-email"
    class="inputfield w-full"
    aria-describedby="email-help"
    :placeholder="t('placeholder.select', { property: t('client.email').toLowerCase() })"
    :class="{ 'p-invalid': val.email.$error }"
  />
  <small data-testid="client-email-error" class="p-error" v-if="val.email.$error">{{
    val.email.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required, email as validateEmail } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const emailComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const { t } = useI18n();

const rules = {
  email: {
    validateEmail: helpers.withMessage(() => t("validations.email"), validateEmail),
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
