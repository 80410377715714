<template>
  <label for="company-telephone-number"> {{ t(`company.telephone-number`) }} </label>

  <InputMask
    mask="999 99 999"
    id="company-telephone-number"
    v-model="telephoneNo"
    :unmask="true"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.telephoneNumber.$error }"
    maxlength="15"
    :disabled="disabled"
    @blur="updateTelephoneNumber"
  />

  <small class="p-error" v-if="val.telephoneNumber.$error" data-testid="company-telephone-number-error">
    {{ val.telephoneNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  telephoneNumber: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "update:telephoneNumber", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  telephoneNumber: {
    required,
  },
};

const val = useVuelidate(rules, props);

const telephoneNo = ref<string>(props.telephoneNumber ?? "");
watch(
  () => props.telephoneNumber,
  (value) => (telephoneNo.value = value ?? ""),
);
const updateTelephoneNumber = () => {
  emit("update:telephoneNumber", telephoneNo.value.replace(/[\s_]+/g, ""));
};
</script>
