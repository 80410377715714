import { httpClient, type AuthHeaders } from "@cumulus/http";
import { ImportResultMessage } from "../import/model/ImportResultMessage";

class CustomerApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_ORDER != undefined && import.meta.env.VITE_APP_API_ORDER
        ? import.meta.env.VITE_APP_API_ORDER + "/customers"
        : `${import.meta.env.VITE_APP_API_URL as string}/customers`;
  }

  public async importBusinessFile(authHeaders: AuthHeaders, file: File): Promise<ImportResultMessage> {
    const formData = new FormData(); //FormData have Content-Type: multipart/form-data
    formData.append("file", file);
    return await httpClient(this.uri, authHeaders).postForm("/import?customerType=Business", formData);
  }
}
const customerApi = new CustomerApi();
export { customerApi };
