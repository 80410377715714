<template>
  <div class="c-page-content h-auto !overflow-hidden">
    <div class="grid grid-cols-12 my-5 ml-5">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #title>
            <EmployeeToolbar
              :isSortingVisible="sortField !== ''"
              :isFilterVisible="showSidebar"
              @onExport="exportCSV"
              @refreshList="onRefreshList"
              @onToggleFilterSidebar="onToggleFilterSidebar"
            />
          </template>

          <template #content>
            <div class="mx-4 my-0">
              <div class="flex items-left gap-2 mt-3">
                <FilterOverview v-model:filters="filtersComputed" />
              </div>
              <EmployeeList
                :employees="employees"
                :clients="clients"
                :loading="loading"
                :page="page"
                :pageSize="pageSize"
                :totalHits="totalHits"
                @update:page="onUpdatePage"
                ref="employeeListRef"
                @update:pageSize="onUpdatePageSize"
              />
            </div>
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" :header="t('employee.search.filter')" position="right">
          <EmployeesFilterSidebar v-model:filters="filtersComputed" :loading="loading" />

          <!-- <EmployeesFilterSidebar :showSidebar="showSidebar" v-model:filters="filtersComputed" /> -->
        </Drawer>
      </div>

      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="ml-5 min-h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("employee.search.filter") }}</span>
            </div>

            <EmployeesFilterSidebar v-model:filters="filtersComputed" :loading="loading" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { Employee } from "@/repositories/employee/model/Employee";
import { useEmployee } from "@/repositories/employee/EmployeeService";
import EmployeesFilterSidebar from "../components/EmployeesFilterSidebar.vue";

import EmployeeToolbar from "./EmployeeToolbar.vue";
import EmployeeList from "../components/EmployeeList.vue";
import { EmployeeSearchFilters } from "@/repositories/employee/model/EmployeeSearchFilters";
import { type Client } from "@/repositories/employee/client/model/Client";
import { useMobile } from "@/utils/MobileService";
import FilterOverview from "./FilterOverview.vue";

const { t } = useI18n();

const { getEmployees, getClients } = useEmployee();

const employees = ref<Employee[]>([]);
const employeeListRef = ref();
const showSidebar = ref(true);
const loading = ref(false);
const pageSize = ref<number>(50);
const page = ref(1);
const totalHits = ref(0);
const clients = ref<Client[]>([]);
const filters = ref<EmployeeSearchFilters>(new EmployeeSearchFilters());
const sortField = ref("");

const { isMobile } = useMobile();
watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

const exportCSV = () => {
  employeeListRef.value.exportCSV();
};

const filtersComputed = computed<EmployeeSearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: EmployeeSearchFilters) => {
  filters.value = value;
  loading.value = true;
  await loadEmployees();
};

const onUpdatePageSize = async (value: number) => {
  pageSize.value = value;
  loading.value = true;
  await loadEmployees();
};

const loadEmployees = async () => {
  try {
    employees.value = await getEmployees();
    clients.value = await getClients();
    totalHits.value = employees.value.length;
  } finally {
    loading.value = false;
  }
};
const onRefreshList = async () => {
  loading.value = true;
  await loadEmployees();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  loadEmployees();
};

onMounted(async () => {
  loading.value = true;
  await loadEmployees();
});
</script>

<style scoped lang="scss">
.c-employees {
  margin: var(--default-content-margin);
}
</style>
