import { useAuth } from "@cumulus/event-bus";
import { clientApi } from "./ClientApi";
import { Client } from "./model/Client";
import { useErrorHandler } from "../ErrorHandler";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { BadRequestError } from "@cumulus/http";

export function useClient() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const toast = useCumulusToast(useToast());

  const getClients = async (): Promise<Client[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const clients = await clientApi.getAll(authHeaders);
      return clients;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getClient = async (id: string): Promise<Client> => {
    try {
      const authHeaders = await getAuthHeaders();
      const client = await clientApi.get(authHeaders, id);
      return client;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createClient = async (client: Client): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientApi.create(authHeaders, client);
    } catch (error) {
      if (error instanceof BadRequestError) {
        toast.add({
          severity: "error",
          summary: error.message[0].errorKey,
          detail: error.message[0].errorMessage,
        });
        throw error;
      } else {
        await handleError(error);
        throw error;
      }
    }
  };

  const updateClient = async (client: Client): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientApi.update(authHeaders, client);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteClient = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getClients,
    getClient,
    createClient,
    updateClient,
    deleteClient,
  };
}
