<template>
  <FloatLabel variant="on">
    <Select
      id="default-warehouse"
      data-testid="default-warehouse"
      pt:list:data-testid="default-warehouse-list"
      class="w-full"
      optionLabel="name"
      optionValue="id"
      :options="warehouseOptions"
      v-model="defaultWarehouse"
      :invalid="val.defaultWarehouseId.$error"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.default-warehouse').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="default-warehouse">{{ t("employee.default-warehouse") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="val.defaultWarehouseId.$error" data-testid="warehouses-error">
    {{ val.defaultWarehouseId.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { type Warehouse } from "@/repositories/employee/model/Warehouse";
import useValidate from "@vuelidate/core";

const { t } = useI18n();

const props = defineProps<{ defaultWarehouseId: string; warehouseOptions: Warehouse[] }>();
const emit = defineEmits<{ (e: "update:defaultWarehouseId", value: string): void }>();

const defaultWarehouse = computed<string>({
  get: () => props.defaultWarehouseId,
  set: (warehouseId) => emit("update:defaultWarehouseId", warehouseId),
});

const val = useValidate({ defaultWarehouseId: { required } }, props);
</script>
