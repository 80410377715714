<template>
  <div class="col-span-12 lg:col pl-4" data-testid="client-logo">
    <div class="text-center mt-2">
      <label for="client-logo">{{ t("client.logo") }}</label>
      <FileUpload
        mode="basic"
        accept="image/*"
        :chooseLabel="t('client.import-logo')"
        class="w-4/12 text-left my-2"
        customUpload
        @uploader="uploader"
        :disabled="uploadInProgress"
        :auto="true"
        data-testid="logo-file-upload"
        :max-file-size="200000"
        :invalid-file-size-message="`{0}: ${t('client.invalid-file-size')} {1}`"
        :invalid-file-type-message="`{0}: ${t('client.invalid-file-type', { fileTypes: 'image/*' })}`"
      />
      <PrimeImage v-if="props.logo !== ''" :src="props.logo" alt="logo" class="mt-8" width="270" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { FileUploadUploaderEvent } from "primevue/fileupload";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const toast = useCumulusToast(useToast());
const { t } = useI18n();
const uploadInProgress = ref(false);
const props = defineProps<{
  logo: string;
}>();

const emit = defineEmits<{
  (e: "update:logo", value: string): void;
}>();

const uploader = (event: FileUploadUploaderEvent) => {
  const file = Array.isArray(event.files) ? event.files[0] : event.files;
  const reader = new FileReader();

  reader.onloadend = function () {
    if (typeof reader.result === "string") {
      emit("update:logo", reader.result);
    } else {
      toast.add({
        severity: "error",
        summary: t("client.toast.error.file-upload-failed"),
        detail: t("client.toast.error.file-upload-failed-detail"),
      });
    }
  };
  reader.readAsDataURL(file);
};
</script>
