import { httpClient, type AuthHeaders } from "@cumulus/http";
import { ImportResultMessage } from "../import/model/ImportResultMessage";
import { Supplier } from "./model/supplier";

class SupplierApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_PRODUCT != undefined && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/suppliers"
        : `${import.meta.env.VITE_APP_API_URL as string}/suppliers`;
  }

  public async importFile(authHeaders: AuthHeaders, file: File): Promise<ImportResultMessage> {
    const formData = new FormData(); //FormData have Content-Type: multipart/form-data
    formData.append("file", file);
    return await httpClient(this.uri, authHeaders).postForm("/import", formData);
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Supplier[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }
}
const supplierApi = new SupplierApi();
export { supplierApi };
