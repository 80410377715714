import { httpClient, AuthHeaders } from "@cumulus/http";
import { Client } from "./model/Client";

class ClientApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_COMPANY != undefined && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY + "/clients"
        : `${import.meta.env.VITE_APP_API_URL as string}/clients`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Client[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Client> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, client: Client): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", client);
  }

  public async update(authHeaders: AuthHeaders, client: Client): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", client);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}

const clientApi = new ClientApi();
export { clientApi };
