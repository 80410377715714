import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { ImportType } from "./model/ImportType";
import { ImportResultMessage, ResultMessage } from "./model/ImportResultMessage";
import { supplierApi } from "@/repositories/supplier/SupplierApi";
import { customerApi } from "@/repositories/customer/CustomerApi";
import { productApi } from "@/repositories/product/ProductApi";
import { ref } from "vue";
import { Supplier } from "../supplier/model/supplier";

export function useImport() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const importResultMessage = ref(new ImportResultMessage());

  const importCsvFile = async (file: File, type: ImportType): Promise<ImportResultMessage> => {
    try {
      const authHeaders = await getAuthHeaders();

      if (type === ImportType.Supplier) {
        return await supplierApi.importFile(authHeaders, file);
      }

      if (type === ImportType.BusinessCustomer) {
        return await customerApi.importBusinessFile(authHeaders, file);
      }

      if (type === ImportType.Products) {
        return await productApi.importSimpleCsvFile(authHeaders, file);
      }

      const resultMessage = new ResultMessage();
      resultMessage.errorKey = "Error type";
      resultMessage.errorMessage = "Invalid import type";

      const importResultMessage = new ImportResultMessage();
      importResultMessage.status = 400;
      importResultMessage.message.push(resultMessage);

      return importResultMessage;
    } catch (error: ImportResultMessage | unknown) {
      await handleError(error);
      throw error;
    }
  };

  const suppliers = async (): Promise<Supplier[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const countries = await supplierApi.getAll(authHeaders);
      return countries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { importCsvFile, importResultMessage, suppliers };
}
