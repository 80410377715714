import { createRouter, createWebHistory } from "vue-router";
import {
  mainRoutes,
  employeeRoutes,
  clientRoutes,
  companyRoutes,
  importRoutes,
  settingsRoutes,
  printerRoutes,
} from "./routes";

import { registerGuard } from "@cumulus/event-bus";

const routes = [
  ...mainRoutes,
  ...employeeRoutes,
  ...clientRoutes,
  ...companyRoutes,
  ...importRoutes,
  ...settingsRoutes,
  ...printerRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  linkActiveClass: "active",
});

registerGuard(router);

export default router;
