<template>
  <FloatLabel variant="on" class="c-datepicker">
    <InputText
      :id="props.id"
      type="date"
      class="c-datepicker-input w-full"
      :data-testid="props.id"
      v-model="dateComputed"
      @input="debouncedInput"
      v-tooltip.bottom="{
        value: placeholder,
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label :for="props.id">{{ label }}</label>
  </FloatLabel>

  <small class="p-error" v-if="val.date.$error" :data-testid="`${props.id}-error`">{{
    val.date.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { debounce } from "vue-debounce";

const props = defineProps<{
  id: string;
  date: string;
  label: string;
  placeholder: string;
}>();

const emit = defineEmits<{
  (e: "update:date", value: string): void;
}>();

const dateComputed = computed({
  get: () => {
    return dateFormatted(props.date);
  },
  set: (value) => {
    emit("update:date", value);
  },
});

const dateFormatted = (value: string | undefined): string => {
  const date = value ? new Date(value) : "";
  if (date !== "") {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const mm = month < 10 ? "0" + month : month;
    const dd = day < 10 ? "0" + day : day;
    return date.getFullYear() + "-" + mm + "-" + dd;
  }
  return "";
};

const debouncedInput = debounce((event: Event) => update(event), 300);

const update = (event: Event | null) => {
  if (event && event.target) {
    const value = (event.target as HTMLInputElement).value;
    const date = value == null || value === "" ? new Date().toJSON() : new Date(value).toJSON();
    const yearPart = date.slice(0, 4);
    const yearValue = parseInt(yearPart);

    if (yearValue < 1900 || isNaN(yearValue)) {
      const currentYear = new Date().getFullYear();
      const updatedDate = date.replace(yearPart, currentYear.toString());
      emit("update:date", updatedDate);
    } else {
      emit("update:date", date);
    }
  }
};

const rules = {
  date: {
    required,
  },
};

const val = useValidate(rules, props);
</script>
