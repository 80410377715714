<template>
  <FloatLabel variant="on">
    <InputText
      id="phone-number"
      data-testid="supplier-phone-number"
      aria-describedby="employee-phone-number"
      type="tel"
      class="w-full"
      v-model="phoneNumberComputed"
      :invalid="val.phoneNumber.$error"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('employee.phone-number').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="phone-number">{{ t("employee.phone-number") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="val.phoneNumber.$error" data-testid="phone-number-error">{{
    val.phoneNumber.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required, minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  phoneNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:phoneNumber", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  phoneNumber: {
    required,
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(8)),
  },
};

const val = useValidate(rules, props);

const phoneNumberComputed = computed<string>({
  get: () => {
    return props.phoneNumber ?? "";
  },
  set: (value) => {
    emit("update:phoneNumber", value);
  },
});
</script>
