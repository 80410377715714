<template>
  <FloatLabel variant="on">
    <InputText
      ref="inputRef"
      id="first-name"
      data-testid="first-name"
      class="w-full"
      v-model="firstNameComputed"
      :invalid="val.firstName.$error"
      maxlength="100"
      v-tooltip.focus.bottom="{
        value: t('placeholder.select', { property: t('employee.first-name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="first-name">{{ t("employee.first-name") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="val.firstName.$error" data-testid="first-name-error">
    {{ val.firstName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed, ref } from "vue";

const { t } = useI18n();

const props = defineProps<{
  firstName: string;
}>();

const emit = defineEmits<{
  (e: "update:firstName", value: string): void;
}>();

const firstNameComputed = computed<string>({
  get: () => {
    return props.firstName ?? "";
  },
  set: (value) => {
    emit("update:firstName", value);
  },
});

const rules = {
  firstName: {
    required,
  },
};

const val = useValidate(rules, props);

const inputRef = ref();
const focus = () => {
  inputRef.value.$el.focus();
};

defineExpose({
  focus,
});
</script>
