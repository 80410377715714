import { type Currency } from "./model/Currency";

class CurrencyRepository {
  public getAll(): Promise<Currency[]> {
    return new Promise(function (resolve) {
      const response = [
        { name: "Norwegian kroner", iso: "NOK" },
        { name: "Swedish kroner", iso: "SEK" },
        { name: "Euro", iso: "EUR" },
      ] as Currency[];

      resolve(response);
    });
  }
}

const currencyRepository = new CurrencyRepository();
export { currencyRepository };
