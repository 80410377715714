<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button
        class="mr-8 text-sm"
        variant="text"
        size="small"
        data-testid="add-employee-btn"
        @click="router.push({ name: 'employee-add' })"
      >
        <i class="pi pi-plus c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.add") }}</span>
      </Button>

      <Button class="mr-8 text-sm" variant="text" size="small" @click="$emit('onExport')">
        <i class="pi pi-upload c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("employee.export") }}</span>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="filter-button"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isFilterVisible }"
        @click="emit('onToggleFilterSidebar')"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();

const { t } = useI18n();

const props = defineProps<{
  isSortingVisible: boolean;
  isFilterVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "onExport"): void;
  (e: "refreshList"): void;
  (e: "onToggleFilterSidebar"): void;
}>();
</script>
