import { EmployeeState } from "./EmployeeState";
import { UserPreferences } from "@cumulus/event-bus";

export class NewEmployee {
  id = "";
  companyId = "";
  defaultClientId = "";
  warehouseId = "";
  firstName = "";
  lastName = "";
  initials = "";
  email = "";
  phoneNumber = "";
  startDate = new Date().toJSON();
  deactivationDate = new Date(new Date((new Date().getFullYear() + 80).toString())).toJSON();
  clients: string[] = [];
  roles: string[] = [];
  employeeState: EmployeeState = EmployeeState.Active;
  languageCode = "NO";
  active = true;
  preferences: UserPreferences = new UserPreferences();
}
