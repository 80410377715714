<template>
  <div class="mx-6">
    <Card>
      <template #content>
        <div class="grid grid-cols-12 gap-4 mt-2">
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <div class="formgrid grid grid-cols-12 gap-4">
              <div class="col-span-12 w-full">
                <span class="font-bold mb-2">{{ t("settings.freight.header") }}</span>
                <div>
                  <Button
                    :label="t('settings.freight.freight-method')"
                    class="p-button-text pl-0 w-full text-left text-sm justify-start"
                    @click="router.push(`/freight/methods`)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <div class="formgrid grid grid-cols-12 gap-4">
              <div class="col-span-12 w-full">
                <span class="font-bold mb-2">{{ t("settings.warehouse.header") }}</span>
                <div>
                  <Button
                    :label="t('settings.warehouse.warehouse-items')"
                    class="p-button-text pl-0 w-full text-left text-sm justify-start"
                    @click="router.push(`/warehouse/configurations`)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <div class="formgrid grid grid-cols-12 gap-4">
              <div class="col-span-12 w-full">
                <span class="font-bold mb-2">{{ t("settings.import.header") }}</span>
                <div>
                  <Button
                    :label="t('settings.import.imports')"
                    class="p-button-text pl-0 w-full text-left text-sm justify-start"
                    @click="router.push(`/system-data/imports`)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const router = useRouter();

const { t } = useI18n();
</script>
