import { useErrorHandler } from "@/repositories/ErrorHandler";
import { documentApi } from "./DocumentApi";
import { useAuth } from "@cumulus/event-bus";
import { DefaultPrinterInfo } from "../models/DefaultPrinterInfo";
import { DocumentType } from "../models/DocumentType";

export function useDocumentService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllPrinters = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getAllPrinters(authHeaders);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const getAllDefaultPrinters = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getDefaultPrinters(authHeaders);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const setDefaultPrinter = async (printerInfo: DefaultPrinterInfo) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.setDefaultPrinter(authHeaders, printerInfo);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const deleteDefaultPrinter = async (documentType: DocumentType) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.deleteDefaultPrinter(authHeaders, documentType);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  return {
    getAllPrinters,
    deleteDefaultPrinter,
    getAllDefaultPrinters,
    setDefaultPrinter,
  };
}
