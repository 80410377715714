<template>
  <div class="field col-span-12 md:col-span-12">
    <label for="address-lines">{{ t("common.address.address-lines") }}</label>
    <PrimeTextarea
      id="address-lines"
      v-model="addressLinesComputed"
      type="text"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.addressLines.$error }"
      @change="onChangeComplete"
      :data-testid="`${addressType}-address-lines`"
      :disabled="disabled"
    />
    <small class="p-error" v-if="val.addressLines.$error" :id="`${addressType}-address-lines-error`">
      {{ val.addressLines.$errors[0].$message }}
    </small>
  </div>
  <div class="field col-span-12 md:col-span-12">
    <label for="address-country">{{ t("common.address.country") }}</label>
    <Select
      id="address-country"
      :options="countryOptions"
      optionLabel="name"
      optionValue="iso"
      v-model="selectedCountryComputed"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.countryIso.$error }"
      @change="onChangeComplete"
      :data-testid="`${addressType}-country`"
      :disabled="disabled"
      :pt:list:data-testid="`${addressType}-country-list`"
    />
    <small class="p-error" v-if="val.countryIso.$error" :id="`${addressType}-country-error`">{{
      val.countryIso.$errors[0].$message
    }}</small>
  </div>

  <div class="field col-span-12 md:col-span-12">
    <label for="address-postal-code">{{ t("common.address.postal-code-city") }}</label>
    <div class="formgrid grid grid-cols-12 gap-4">
      <div class="field col-span-12 md:col-span-4">
        <InputText
          id="address-postal-code"
          v-model="addressComputed.postalCode"
          maxlength="4"
          type="text"
          class="inputfield w-full"
          :class="{ 'p-invalid': val.postalCode.$error }"
          @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
          :data-testid="`${addressType}-postal-code`"
          :disabled="disabled"
        />
        <small class="p-error" v-if="val.postalCode.$error" :id="`${addressType}-postal-code-error`">{{
          val.postalCode.$errors[0].$message
        }}</small>
      </div>
      <div class="field col-span-12 md:col-span-8">
        <InputText
          id="address-city"
          type="text"
          v-model="addressComputed.city"
          class="inputfield w-full"
          :class="{ 'p-invalid': val.city.$error }"
          @change="onChangeComplete"
          :data-testid="`${addressType}-city`"
          :disabled="disabled"
          :aria-label="t('common.address.city')"
        />
        <small class="p-error" v-if="val.city.$error" :id="`${addressType}-city-error`">{{
          val.city.$errors[0].$message
        }}</small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Address } from "@/repositories/company/model/Address";
import { Country } from "@/repositories/country/model/Country";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import { useCountry } from "@/repositories/country/CountryService";
import Select from "primevue/select";

const { t } = useI18n();

const props = defineProps<{
  address: Address;
  countryOptions: Country[];
  addressType: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "setAddress", value: Address): void;
}>();

const addressComputed = computed<Address>(() => {
  return props.address ? cloneDeep(props.address) : ({} as Address);
});

const addressLinesComputed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines ? addressComputed.value.addressLines.join("\n") : "";
  },
  set: (val: string) => {
    addressComputed.value.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    return addressComputed.value?.countryIso ?? "";
  },
  set: (value) => {
    if (value !== undefined) {
      addressComputed.value.countryIso = value;
    }
  },
});

const onChangeComplete = () => {
  emit("setAddress", addressComputed.value);
};

const { getCities } = useCountry();

const onPostalCodeChange = async (code: string) => {
  if (code === undefined || code.length < 3) {
    return;
  }
  const response = await getCities(code);
  addressComputed.value.city = response[0].city;
  onChangeComplete();
};

const rules = {
  addressLines: {
    required,
    $each: helpers.withMessage(t("common.address.address-lines-error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
  countryIso: {
    required,
  },
};

const val = useVuelidate(rules, addressComputed);
</script>
