<template>
  <FloatLabelDropdown
    id="input-style"
    dataTestId="input-style"
    v-model:value="inputStyle"
    :options="dropdownOptions"
    :label="t(`employee.input-style`)"
    :placeholder="t('placeholder.select', { property: t('employee.input-style').toLowerCase() })"
    :isFloatingLabel="isFloatingLabel"
  />
</template>

<script setup lang="ts">
import { InputStyle } from "@cumulus/event-bus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const inputStyle = defineModel<string>("inputStyle");

const { t } = useI18n();

const dropdownOptions = computed(() => {
  const keys = Object.keys(InputStyle);
  return keys.map((key) => {
    return { name: t(`employee.input-style-type.${key.toLowerCase()}`), value: key };
  });
});

const isFloatingLabel = computed(() => {
  if (inputStyle.value === undefined) {
    return false;
  }

  return inputStyle.value !== "";
});
</script>
