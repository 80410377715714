import { ClientAddress } from "./ClientAddress";
import { ClientBanking } from "./ClientBanking";
import { ClientState } from "./ClientState";

export class Client {
  id = "";
  companyId = "";
  name = "";
  businessNumber = "";
  email = "";
  phoneNumber = "";
  address = new ClientAddress();
  banking = new ClientBanking();
  countryIso = "";
  currencyIso = "";
  clientState: ClientState = ClientState.Active;
  logoBase64 = "";
}
