<template>
  <div class="field p-2">
    <label for="name">
      {{ t(`client.banking.name`) }}
    </label>
    <InputText
      id="name"
      type="text"
      v-model="bankingComputed.name"
      data-testid="banking-name"
      class="inputfield w-full"
      :placeholder="t('placeholder.select', { property: t('client.banking.name').toLowerCase() })"
      :class="{ 'p-invalid': val.banking.name.$error }"
    />
    <small data-testid="banking-name-error" class="p-error" v-if="val.banking.name.$error">{{
      val.banking.name.$errors[0].$message
    }}</small>
  </div>
  <div class="field p-2">
    <label for="account-number">
      {{ t(`client.banking.account-number`) }}
    </label>
    <InputText
      id="account-number"
      type="text"
      v-model="bankingComputed.accountNumber"
      data-testid="banking-account-number"
      class="inputfield w-full"
      aria-describedby="city-help"
      :placeholder="t('placeholder.select', { property: t('client.banking.account-number').toLowerCase() })"
      :class="{ 'p-invalid': val.banking.accountNumber.$error }"
    />
    <small data-testid="banking-account-number-error" class="p-error" v-if="val.banking.accountNumber.$error">{{
      val.banking.accountNumber.$errors[0].$message
    }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ClientBanking } from "@/repositories/client/model/ClientBanking";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  banking: ClientBanking;
}>();

const emit = defineEmits<{
  (e: "update:banking", value: ClientBanking): void;
}>();

const { t } = useI18n();

const bankingComputed = computed<ClientBanking>({
  get: () => {
    return props.banking ?? ({} as ClientBanking);
  },
  set: (value) => {
    emit("update:banking", value);
  },
});

const rules = {
  banking: {
    name: {
      required,
    },
    accountNumber: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);
</script>
