<template>
  <h4 class="text-center">{{ t("common.filters.filter-employee-status") }}</h4>
  <div
    v-for="(value, index) in allStatusesComputed"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md"
    :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
  >
    <div class="centered-child">
      <label class="font-light" :for="'purchase-status-' + value.status.toLowerCase()">{{
        t(`common.filters.filter-status-${value.status.toLowerCase()}`)
      }}</label>
    </div>
    <div class="ml-auto">
      <Checkbox
        v-model="statusesComputed"
        :value="value.status"
        :inputId="'purchase-status-' + value.status.toLowerCase()"
        :data-testid="'common.filters.filter-status-' + value.status.toLowerCase()"
      />
    </div>
  </div>

  <hr class="c-seperator-line" />
  <h4 class="text-center">{{ t("common.filters.filter-clients") }}</h4>
  <MultiSelect
    id="clients"
    data-testid="inventory-manufacturers"
    :placeholder="t('placeholder.select', { property: t('common.filters.filter-clients').toLowerCase() })"
    optionLabel="name"
    optionValue="id"
    display="chip"
    class="w-full field"
    :filter="true"
    :loading="loading"
  />

  <h4 class="text-center">{{ t("common.filters.filter-roles") }}</h4>
  <MultiSelect
    id="employee-roles"
    data-testid="inventory-manufacturers"
    :placeholder="t('placeholder.select', { property: t('common.filters.filter-roles').toLowerCase() })"
    optionLabel="name"
    optionValue="id"
    display="chip"
    class="w-full field"
    :filter="true"
    :loading="loading"
  />
  <hr class="c-seperator-line" />

  <h4 class="text-center">{{ t("common.filters.filter-deactivation-date") }}</h4>
  <div class="formgrid grid grid-cols-12 gap-4">
    <div class="col-span-6 field text-center">
      <CumulusDatePicker
        v-model:date="fromDateComputed"
        :dataTestId="'from-date-filter'"
        :label="t('common.filters.filter-date-from', { symbol: ':' })"
        :debounceTime="400"
      />
    </div>
    <div class="col-span-6 field text-center">
      <CumulusDatePicker
        v-model:date="toDateComputed"
        :dataTestId="'to-date-filter'"
        :label="t('common.filters.filter-date-to')"
        :debounceTime="400"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { EmployeeSearchFilters } from "@/repositories/employee/model/EmployeeSearchFilters";
import { EmployeeState } from "@/repositories/employee/model/EmployeeState";
import { CumulusDatePicker } from "@cumulus/components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  filters: EmployeeSearchFilters;
  loading: boolean;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: EmployeeSearchFilters): void;
}>();

const statusesComputed = computed<EmployeeState[]>({
  get: () => props.filters.employeeState,
  set: (value) => {
    const filters = { ...props.filters, employeeState: value };
    emit("update:filters", filters);
  },
});

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(EmployeeState);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const dateFormatted = (value: string): string => {
  const date = value ? new Date(value) : new Date();
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1; // Months start at 0 index!
  const day = date.getDate();
  const mm = month < 10 ? "0" + month : month;
  const dd = day < 10 ? "0" + day : day;
  return yyyy + "-" + mm + "-" + dd;
};

const fromDateComputed = computed<string>({
  get: () => {
    if (props.filters.fromDate) {
      return dateFormatted(props.filters.fromDate);
    }
    return "";
  },
  set: (value) => {
    let fromDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.fromDate === null) return;
    } else {
      fromDate = new Date(value).toJSON();
      if (props.filters.fromDate === fromDate) return;
    }

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    if (props.filters.toDate) {
      return dateFormatted(props.filters.toDate);
    }
    return "";
  },
  set: (value) => {
    let toDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.toDate === null) return;
    } else {
      toDate = new Date(value).toJSON();
      if (props.filters.toDate === toDate) return;
    }

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});
</script>
