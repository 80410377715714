import { useAuth } from "@cumulus/event-bus";
import { employeeApi } from "./EmployeeApi";
import { warehouseApi } from "./warehouse/WarehouseApi";
import { clientApi } from "./client/ClientApi";
import { NewEmployee } from "./model/NewEmployee";
import { Employee } from "./model/Employee";
import { type Client } from "./client/model/Client";
import { useErrorHandler } from "../ErrorHandler";
import { type Warehouse } from "./model/Warehouse";

export function useEmployee() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getEmployees = async (): Promise<Employee[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const employees = await employeeApi.getAll(authHeaders);
      return employees;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getEmployee = async (id: string): Promise<Employee> => {
    try {
      const authHeaders = await getAuthHeaders();
      const employee = await employeeApi.get(authHeaders, id);
      return employee;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createEmployee = async (employee: NewEmployee): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await employeeApi.create(authHeaders, employee);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateEmployee = async (employee: Employee): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await employeeApi.update(authHeaders, employee);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteEmployee = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await employeeApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getClients = async (): Promise<Client[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const clients = await clientApi.getClients(authHeaders);
      return clients;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getWarehouses = async (): Promise<Warehouse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const warehouses = await warehouseApi.getWarehouses(authHeaders);
      return warehouses;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getEmployees,
    getEmployee,
    createEmployee,
    updateEmployee,
    deleteEmployee,
    getClients,
    getWarehouses,
  };
}
