<template>
  <label for="client-name">{{ t("client.name") }}</label>
  <InputText
    id="client-name"
    type="text"
    v-model="nameComputed"
    data-testid="client-name"
    autofocus
    class="inputfield w-full"
    :class="{ 'p-invalid': val.name.$error }"
    :placeholder="t('placeholder.select', { property: t('client.name').toLowerCase() })"
  />
  <small data-testid="client-name-error" class="p-error" v-if="val.name.$error">{{
    val.name.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const { t } = useI18n();

const nameComputed = computed<string>({
  get: () => {
    return props.name ?? "";
  },
  set: (value) => {
    emit("update:name", value);
  },
});

const rules = {
  name: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
