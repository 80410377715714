<template>
  <FloatLabel variant="on">
    <InputText
      id="email"
      data-testid="email"
      class="w-full"
      v-model="emailComputed"
      :invalid="val.email.$error"
      maxlength="100"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('employee.email').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="email">{{ t("employee.email") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="val.email.$error" data-testid="email-error">
    {{ val.email.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required, email as validateEmail } from "@/locales/i18n-validators";
import { computed } from "vue";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const { t } = useI18n();

const emailComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const rules = {
  email: {
    required,
    validateEmail: helpers.withMessage(() => t("validations.email"), validateEmail),
  },
};

const val = useValidate(rules, props);
</script>
