<template>
  <FloatLabel variant="on">
    <Select
      id="employee-language"
      data-testid="language"
      pt:list:data-testid="language-list"
      class="w-full"
      :options="languages"
      optionLabel="name"
      optionValue="code"
      v-model="selectedLanguage"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.language').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    >
      <template #option="slotProps">
        <div class="flex items-center">
          <img
            src="@/assets/flag_placeholder.png"
            :class="'flag flag-' + flaggConvertion(slotProps.option.code.toLowerCase())"
            width="16"
          />
          <div class="ml-4">{{ slotProps.option.name }}</div>
        </div>
      </template>
    </Select>
    <label for="employee-language">
      {{ t(`employee.language`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  languageCode: string;
}>();

const emit = defineEmits<{
  (e: "update:languageCode", value: string): void;
}>();

const { t } = useI18n();
const languages = ref<Array<{ name: string; code: string }>>([]);

const selectedLanguage = computed<string>({
  get: () => {
    return props.languageCode ?? "";
  },
  set: (value) => {
    emit("update:languageCode", value);
  },
});

const fetchLanguages = () => {
  languages.value = [
    {
      name: t("employee.languages.en"),
      code: "EN",
    },
    {
      name: t("employee.languages.no"),
      code: "NO",
    },
  ];

  if (selectedLanguage.value === "") {
    selectedLanguage.value = languages.value[0].code;
  }
};
onMounted(fetchLanguages);

const flaggConvertion = (language: string) => {
  switch (language) {
    case "en":
      return "gb";
  }
  return language;
};
</script>
