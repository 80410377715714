<template>
  <label for="company-email"> {{ t(`company.email`) }} </label>

  <InputText
    id="company-email"
    type="email"
    v-model="emailComputed"
    data-testid="company-email"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.email.$error }"
    maxlength="8000"
    :disabled="disabled"
  />

  <small class="p-error" v-if="val.email.$error" data-testid="company-email-error">
    {{ val.email.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { WritableComputedRef, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, email as validateEmail } from "@/locales/i18n-validators";

const props = defineProps<{
  email: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const { t } = useI18n();

const emailComputed: WritableComputedRef<string> = computed({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const rules = {
  email: {
    required,
    validateEmail,
  },
};

const val = useVuelidate(rules, props);
</script>
