<template>
  <PrimeDialog
    position="top"
    data-testid="unsaved-changes-dialog"
    :visible="props.visible"
    :header="t('common.unsaved-changes-header')"
    :style="{ width: '30vw' }"
    modal
    :breakpoints="{ '1560px': '50vw', '960px': '70vw', '641px': '100vw' }"
  >
    <div class="mb-4 mx-4">
      {{ t("common.unsaved-changes-text") }}
    </div>

    <template #footer>
      <PrimeButton
        :label="t('common.cancel')"
        @click="emit('cancelClicked')"
        outlined
        data-testid="c-dialog-cancel-btn"
      />
      <PrimeButton
        :label="t('common.discard')"
        @click="emit('discardClicked')"
        outlined
        data-testid="c-dialog-discard-btn"
      />
      <PrimeButton :label="t('common.save')" @click="emit('saveClicked')" autofocus data-testid="c-dialog-save-btn" />
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  visible: boolean;
}>();

const emit = defineEmits<{
  (e: "cancelClicked"): void;
  (e: "discardClicked"): void;
  (e: "saveClicked"): void;
}>();
</script>
