<template>
  <label for="client-phone-number">
    {{ t(`client.phone-number`) }}
  </label>
  <InputMask
    mask="999 99 999"
    v-model="phone"
    id="client-phone-number"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.phoneNumber.$error }"
    :placeholder="t('placeholder.select', { property: t('client.phone-number').toLowerCase() })"
    @blur="updatePhoneNumber"
  />
  <small data-testid="client-phone-number-error" class="p-error" v-if="val.phoneNumber.$error">{{
    val.phoneNumber.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { required, minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  phoneNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:phoneNumber", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  phoneNumber: {
    required,
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(8)),
  },
};

const val = useVuelidate(rules, props);

const phone = ref<string>(props.phoneNumber ?? "");
watch(
  () => props.phoneNumber,
  (value) => (phone.value = value ?? ""),
);
const updatePhoneNumber = () => {
  emit("update:phoneNumber", phone.value.replace(/[\s_]+/g, ""));
};
</script>
