import { useAuth } from "@cumulus/event-bus";
import { countryApi } from "./CountryApi";
import { type Country } from "./model/Country";
import { useErrorHandler } from "../ErrorHandler";
import { type CityResponse } from "./model/CityResponse";

export function useCountry() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCountries = async (): Promise<Country[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const countries = await countryApi.getAll(authHeaders);
      return countries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getCities = async (id: string): Promise<CityResponse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const city = await countryApi.getCities(authHeaders, id);
      return city;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getCountries,
    getCities,
  };
}
