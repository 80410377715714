import { httpClient, type AuthHeaders } from "@cumulus/http";
import { ImportResultMessage } from "../import/model/ImportResultMessage";

class ProductApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_PRODUCT != undefined && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/products"
        : `${import.meta.env.VITE_APP_API_URL as string}/products`;
  }

  public async importSimpleCsvFile(authHeaders: AuthHeaders, file: File): Promise<ImportResultMessage> {
    const formData = new FormData(); //FormData have Content-Type: multipart/form-data
    formData.append("file", file);
    return await httpClient(this.uri, authHeaders).postForm("/import", formData);
  }
}
const productApi = new ProductApi();
export { productApi };
