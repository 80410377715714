<template>
  <FloatLabel variant="on">
    <Select
      id="clients"
      data-testid="clients"
      pt:list:data-testid="clients-list"
      class="w-full"
      optionLabel="name"
      optionValue="id"
      :options="clientOptions"
      v-model="clientsSelected"
      :invalid="val.clients.$error"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.clients').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="clients">{{ t("employee.clients") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="val.clients.$error" data-testid="clients-error">
    {{ val.clients.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { type Client } from "@/repositories/employee/client/model/Client";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import useValidate from "@vuelidate/core";

const { t } = useI18n();

const props = defineProps<{ clients: string[]; clientOptions: Client[] }>();
const emit = defineEmits<{ (e: "update:clients", value: string[]): void }>();

const clientsSelected = computed<string>({
  get: () => {
    return props.clients.length > 0 ? props.clients[0] : "";
  },
  set: (client) => emit("update:clients", [client]),
});

const val = useValidate({ clients: { required } }, props);
</script>
