import { httpClient, type AuthHeaders } from "@cumulus/http";
import { type Company } from "./model/Company";

class CompanyApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_COMPANY != undefined && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY + "/companies"
        : `${import.meta.env.VITE_APP_API_URL as string}/companies`;
  }

  public async getCompany(authHeaders: AuthHeaders): Promise<Company> {
    return httpClient(this.uri, authHeaders)
      .get(authHeaders.companyId)
      .then(({ data }) => data);
  }

  public async update(authHeaders: AuthHeaders, company: Company): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", company);
  }
}
const companyApi = new CompanyApi();
export { companyApi };
